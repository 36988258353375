import React, { ReactElement } from 'react'
import TopBar from 'src/components/Molecules/TopBar'
import { Container, Typography } from '@material-ui/core'
import DealDataGrid from 'src/components/Views/Deals/List'
import { useTranslation } from 'react-i18next'
import commonStyles from 'src/styles/commonStyles'
import Layout from 'src/components/Layout'
import PrivateRoute from 'src/components/PrivateRoute'
import Page from 'src/components/Page'

/**
 * @constructor
 */
export default function DealsPage(): ReactElement {
  const commonClasses = commonStyles()
  const [t] = useTranslation()

  return (
    <Layout>
      <Page>
        <PrivateRoute path="/login">
          <TopBar />
          <Container component="main" className={commonClasses.mainContentContainer}>
            <Typography variant={'h1'}>{t('deal.myOffers')}</Typography>
            <DealDataGrid />
          </Container>
        </PrivateRoute>
      </Page>
    </Layout>
  )
}
