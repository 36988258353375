import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { DataGrid, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid'
import { makeStyles } from '@material-ui/styles'
import { config } from '@yodo/config'
import { Merchant } from '@yodo/types'
import { navigate } from 'gatsby'
import { Button as GatsbyButton } from 'gatsby-theme-material-ui'
import React, { useContext, useState } from 'react'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { useTranslation } from 'react-i18next'
import { Deal } from 'src/types/Deal'
import { Subsidiary } from 'src/types/Merchant'
import { FirebaseContext } from 'src/utils/firebase'
import { navigation } from 'src/utils/navigation'

type Props = {}
export default function DealDataGrid({}: Props) {
  const [t] = useTranslation()
  const classes = useStyles()
  const { firebase, token } = useContext(FirebaseContext)
  const merchantId = token?.claims?.merchantId ?? null

  const [deals, loading, error] = useCollectionData<Deal>(
    firebase
      .firestore()
      .collection(config.collection('deals'))
      .where(
        'merchant',
        '==',
        merchantId ? firebase.firestore().collection('merchants').doc(merchantId) : null
      ),
    {
      idField: 'id'
    }
  )

  const [merchant] = useDocumentData<Merchant>(
    firebase.firestore().collection(config.collection('merchants')).doc(merchantId),
    {
      idField: 'id'
    }
  )

  const [open, setOpen] = useState(false)
  const [duplicateOpen, setDuplicateOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState<GridValueFormatterParams | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClickOpen = (params: GridValueFormatterParams) => {
    setOpen(true)
    setSelectedRow(params)
  }

  const handleDuplicateClick = (params: GridValueFormatterParams) => {
    setDuplicateOpen(true)
    setSelectedRow(params)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = () => {
    setIsSubmitting(true)
    firebase
      .firestore()
      .doc(`deals/${selectedRow?.getValue(selectedRow?.id, 'id')}`)
      .delete()
      .then(() => {
        setIsSubmitting(false)
        setOpen(false)
        setSelectedRow(null)
      })
      .catch((error) => {
        // todo better error handling here
        console.error('Error removing document: ', error)
      })
  }

  const columns = getColumnDefinition({
    t,
    classes,
    handleClickOpen,
    handleDuplicateClick,
    merchant
  })

  return (
    <>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      <DataGrid
        rows={deals ?? []}
        loading={loading}
        density="standard"
        columns={columns}
        disableSelectionOnClick
        headerHeight={90}
        rowHeight={90}
        autoHeight
        autoPageSize
        pageSize={10}
        checkboxSelection={false}
        hideFooterPagination
        hideFooterRowCount
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('confirm.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('confirm.sure?', { value: selectedRow?.getValue(selectedRow.id, 'name') })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isSubmitting}>
            {t('action.cancel')}
          </Button>
          <Button onClick={handleDelete} disabled={isSubmitting} color="primary">
            {t('action.delete')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={duplicateOpen}
        onClose={() => setDuplicateOpen(false)}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('confirm.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('confirm.duplicate?', { value: selectedRow?.getValue(selectedRow.id, 'name') })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDuplicateOpen(false)} disabled={false}>
            {t('action.cancel')}
          </Button>
          <Button
            onClick={() =>
              navigate(
                `${navigation.toDealDuplicate}${selectedRow?.getValue(selectedRow.id, 'id')}`
              )
            }
            disabled={false}
            color="primary">
            {t('action.duplicate')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

type Args = {
  t: any
  classes: any
  handleClickOpen: (params: GridValueFormatterParams) => void
  handleDuplicateClick: (params: GridValueFormatterParams) => void
  merchant: Merchant | undefined
}

function getColumnDefinition({
  t,
  classes,
  handleClickOpen,
  handleDuplicateClick,
  merchant
}: Args): GridColDef[] {
  handleClickOpen // todo remove me when delete button is restored
  return [
    // { field: 'id', headerName: 'ID', hide: true },
    {
      hide: !(
        merchant?.subsidiaries &&
        Array.isArray(merchant?.subsidiaries) &&
        merchant?.subsidiaries.length > 1
      ),
      field: 'subsidiaries',
      width: 200,
      headerName: t('deal.subsidiaries'),
      renderCell: (params: GridValueFormatterParams) => {
        let subsidiaries: string[] = []
        if (Array.isArray(params.value)) {
          subsidiaries = params.value?.map((subsidiary: Subsidiary) => {
            return subsidiary.name
          })
        }
        return <Typography className={classes.breakWord}>{subsidiaries.join(', ')}</Typography>
      }
    },
    { field: 'name', width: 260, headerName: t('deal.offer') },
    {
      field: 'price',
      headerName: t('deal.price'),
      width: 160,
      headerAlign: 'right',
      renderCell: (params: GridValueFormatterParams) => {
        const value = Number(params.value)
        return <div className={classes.right}>{value.toFixed(2)}</div>
      }
    },
    {
      field: 'priceWithDiscount',
      width: 160,
      headerAlign: 'right',
      headerName: t('deal.priceWithDiscount'),
      renderCell: (params: GridValueFormatterParams) => {
        const value = Number(params.value)
        return <div className={classes.right}>{value.toFixed(2)}</div>
      }
    },
    {
      field: 'priceFinal',
      width: 220,
      headerName: t('deal.priceFinal'),
      headerAlign: 'right',
      renderCell: (params: GridValueFormatterParams) => {
        const value = Number(params.value)
        return <div className={classes.right}>{value.toFixed(2)}</div>
      }
    },
    {
      field: 'id',
      headerName: ' ',
      sortable: false,
      width: 300,
      //description: 'Actions',
      renderCell: (params: GridValueFormatterParams) => (
        <>
          <GatsbyButton
            variant="contained"
            color="primary"
            size="small"
            className={classes.btn}
            to={`${navigation.toDealEdit}${params.value}`}>
            {t('action.edit')}
          </GatsbyButton>
          <GatsbyButton
            variant="contained"
            color="primary"
            size="small"
            className={classes.btn}
            onClick={() => {
              handleDuplicateClick(params)
            }}>
            {t('action.duplicate')}
          </GatsbyButton>
          {/*<Button*/}
          {/*  variant="contained"*/}
          {/*  size="small"*/}
          {/*  className={classes.btn}*/}
          {/*  onClick={() => {*/}
          {/*    handleClickOpen(params)*/}
          {/*  }}>*/}
          {/*  {t('action.delete')}*/}
          {/*</Button>*/}
        </>
      )
    }
  ]
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    right: {
      width: '100%',
      textAlign: 'right'
    },
    breakWord: {
      whiteSpace: 'normal',
      wordWrap: 'break-word'
    },
    btn: {
      marginLeft: theme.spacing(2)
    }
  })
)
